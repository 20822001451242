
class CDNService {
    private cdnUrl = process.env.NEXT_PUBLIC_CDN_ENDPOINT || '';
    private tenantCode = 'kieazy-app';

  public readonly imageContainerName = 'images';
  public FILE_UPLOADS_CONTAINER_NAME = 'file-uploads';
  public DEFAULT_UPLOADS_CONTAINER_NAME = 'file-uploads';
  //constructor(tenantCode: string, containerName:string) {
  //    this.sasUrl = process.env.REACT_APP_BLOB_SAS_URL || '';
  //    this.tenantCode = tenantCode;
  //    this.containerName = containerName;
  //}

  public init(tenantCode: string) {
    this.tenantCode = tenantCode;
  }

  //public getContainerClient = (containerName: string): ContainerClient => {
  //  const blobServiceClient = new BlobServiceClient(this.sasUrl);
  //  const containerClient = blobServiceClient.getContainerClient(
  //    this.tenantCode + '/' + containerName
  //  );
  //  return containerClient;
  //};

    public toCdnUrl(fileName: string, containerName?: string) {
        if (containerName)
            return this.cdnUrl + this.tenantCode + '/' + containerName + '/' + fileName;
        else
            return this.cdnUrl + this.tenantCode + '/' + fileName;
    }


    public toImageUrl(fileName: string) {
        const containerName = 'images';
        return this.cdnUrl + this.tenantCode + '/' + containerName + '/' + fileName;
    }

  public noImageUrl() {
    return this.cdnUrl + '_sys/no-image.jpg';
  }
}
const cdnService = new CDNService();
export default cdnService;
