import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, TextField, Typography } from "@mui/material";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useState } from "react";
import { TextField as FmTextFiled } from "formik-mui";
import * as Yup from "yup";
import { saveConnect } from "../../services/apiService";
import { ConnectRequest } from "../../services/apiService/responseModels";
import { useSnackbar } from "notistack";

const rePhoneNumber =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

function ConnectForm(props: { cardId: number; onSave: () => void }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");

  const onSave = async (updatedModel: ConnectRequest) => {
    let isSuccess = false;
    try {
      setIsSaveInProgress(true);
      updatedModel.info=updatedModel.message+', '+updatedModel.meetingDate+', '+updatedModel.meetingLocation;
      updatedModel.message = updatedModel.meetingDate = updatedModel.meetingLocation='';

      await saveConnect(updatedModel);
      enqueueSnackbar(`Thanks for connecting`, { variant: "success" });
      isSuccess = true;
      setIsSaveInProgress(false);
      props.onSave();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
    return isSuccess;
  };

  return (
    <Formik
      initialValues={{
        contactName: "",
        contactEmail: "",
        contactMobile: "",
        meetingDate:'',
        meetingLocation:'',
        message:''

      }}
      validationSchema={Yup.object().shape({
        contactName: Yup.string().max(250).required("The field is required"),
        contactEmail: Yup.string()
          .max(250)
          .required("The field is required")
          .email("Not a proper email"),
        contactMobile: Yup.string()
          .required("The field is required")
          .matches(rePhoneNumber, "Phone number is not valid"),
          meetingDate: Yup.string().max(20),
          meetingLocation: Yup.string().max(80),
          message: Yup.string().max(500),
      })}
      onSubmit={async (
        _values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          if (isSaveInProgress) {
            return;
          }
          const isSuccess = await onSave({ ..._values, cardId: props.cardId });
          if (isSuccess) {
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Card sx={{ mb: 1, p: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="contactName"
                  variant="outlined"
                  label={"Full name"}
                  placeholder={"Enter your name here ..."}
                  component={FmTextFiled}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="contactEmail"
                  variant="outlined"
                  label={"Email"}
                  placeholder={"Enter your email here ..."}
                  component={FmTextFiled}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="contactMobile"
                  variant="outlined"
                  label={"Contact number"}
                  placeholder={"Enter your mobile number here ..."}
                  component={FmTextFiled}
                />
              </Grid>
            </Grid>
          </Card>
          <Card sx={{mb:1}}>
       <CardHeader title={
 <Typography variant="h5">{'Additional information (optional)'}</Typography>
       } >
      
       </CardHeader>
    
        <CardContent
         sx={{pt:0}}
        >
          <Grid   container spacing={1}>
          <Grid item xs={6}>
                <Field
                  fullWidth
                  name="meetingDate"
                  variant="outlined"
                  label={"Date"}
                  placeholder={"Enter meeting date ..."}
                  helperText={'Enter date of meeting or event'}
                  component={FmTextFiled}
                />
              </Grid>
              <Grid item xs={6}>
              <Field
                  fullWidth
                  name="meetingLocation"
                  variant="outlined"
                  label={"Location"}
                  placeholder={"Enter location ..."}
                  helperText={'Enter location where you meet'}
                  component={FmTextFiled}
                />
              </Grid>
          <Grid item xs={12}>
              <Field name="message">
                {({ field, form, meta }:FieldProps) => (
                   <TextField
                   label="Your message"
                   multiline
                   rows={2}
                  fullWidth
                  placeholder="(optional)"
                  helperText={meta.error ? meta.error : ''}
                  error={Boolean(meta.error)}
                  {...field}
                 />
                )}
                </Field>
              </Grid>
          </Grid>
        </CardContent>
      
    
    </Card>
          <Button fullWidth type="submit" size="medium" variant="contained">
            {isSubmitting || isSaveInProgress ? "Please wait..." : "Connect"}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default ConnectForm;
