import { DigitalCardField, CardFieldValue, FormFieldValue } from "../services/apiService/responseModels";

export const getValueDescForSingleFields = (field: DigitalCardField, fieldValue?: CardFieldValue): string => {
    if (!fieldValue)
        return '';

    if (!field.valueDescriptor)
        return fieldValue.formFieldValues.find(o => o.name == field.name)?.value || '';

    //repalce all '{Title} ' with title value
    let label = field.valueDescriptor;
    field.formFields?.forEach(o => {
        const value = fieldValue.formFieldValues.find(fv => fv.name == o.name)?.value || '';
        label = label.replaceAll(new RegExp("\{" + o.name + "\}", "gi"), value);
    });

    return label;
}

//to be replaced with getValueDesc2
// export const getValueDesc = (field: DigitalCardField, formFieldValues?: FormFieldValue): string => {
//     if (!formFieldValues)
//         return '';

//     if (!field.valueDescriptor)
//         return formFieldValues.value || '';

//     //repalce all '{Title} ' with title value
//     let label = field.valueDescriptor;
//     field.formFields?.forEach(o => {
//         const value = formFieldValues?.value || '';
//         label = label.replaceAll(new RegExp("\{" + o.name + "\}", "gi"), value);
//     });

//     return label;
// }


export const getValueDesc = (field: DigitalCardField, formFieldValues?: FormFieldValue[]): string => {
    if (!field || !formFieldValues || !formFieldValues.length)
        return '';

    if (!field.valueDescriptor)
        return formFieldValues.find(o => o.name == field.name)?.value || '';

    //repalce all '{Title} ' with title value
    let label = field.valueDescriptor;
    field.formFields?.forEach(o => {
        const value = formFieldValues.find(fv => fv.name == o.name)?.value || '';
        label = label.replaceAll(new RegExp("\{" + o.name + "\}", "gi"), value);
    });

    return label;
}