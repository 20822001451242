// import PreField from "../../../contents/business-cards/models/PreField";
import PreField from '../../../services/apiService/responseModels/PreField';
import parseMyJson from '../../../utility/parseMyJson';

export default function readFieldValue<T>(field: PreField, defaultVal: T): T {
  const fieldValues = field.fieldValues;

  if (!fieldValues) return defaultVal;

  const fieldValue = parseMyJson(
    fieldValues.find((o) => (o.name = 'config'))?.value,
    defaultVal
  );

  return fieldValue;
}
