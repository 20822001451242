import {
  alpha,
  Box,
  Card,
  Avatar,
  darken,
  CardMedia,
  Typography,
  IconButton,
  Button,
  styled,
  useTheme,
  Tooltip,
  Divider,
  List,
  useMediaQuery,
  CircularProgress,
  TextField,
  Collapse,
  Alert,
  Dialog,
  Slide,
} from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import cdnService from '../../services/cdnService';
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CardActionItem,
  CardFieldNames,
  CardTypes,
  DigitalCard,
  DigitalCardField,
} from '../../services/apiService/responseModels';
import parseCardFieldInfo from '../../utils/parseCardFieldInfo';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import StreamIcon from '@mui/icons-material/Stream';
import { getFixedFields } from '../../utils/getFixedFields';
import { socialFieldNames } from '../../utils/socailFieldNames';
import { Dictionary, first } from 'lodash';
import _ from 'lodash';
import { addHttpPrefixToUrl } from '../../utils/addHttpPrefixToUrl';
import Head from 'next/head';
import { useSnackbar } from 'notistack';
import ConnectForm from '../preview-card/connect-form';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import ShareForm from '../preview-card/share-form';
import { TransitionProps } from '@mui/material/transitions';
import FieldsRegistry from '../field-renderers/_base/FieldsRegistry';
import PreField from '../../services/apiService/responseModels/PreField';
import convertCardFieldsToPreFields from '../../utility/convertCardFieldsToPreFields';
import FieldRenderBlockBaseProp from '../field-renderers/_base/FieldRenderBlockBaseProp';
import { isNewField } from '../field-renderers/_base/StyledComponent';
import parseMyJson from '../../utility/parseMyJson';
import dynamic from 'next/dynamic';
import vCardsJS from "vcards-js";
import { vCardType } from '../../pages/[cardId]';

const CardActionButtonGroup = dynamic(
  () => import('../card-action-button-group'),
  {
    ssr: false,
  }
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow-y:scroll;
      }
`
);

const AvatarShareDialog = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.warning.main};
      color: ${theme.palette.warning.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.warning};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const AvatarConnectDialog = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.secondary.main};
      color: ${theme.palette.secondary.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.info};
      top: -${theme.spacing(6)};
      position: absolute;
      z-index:1000;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const BgComposed = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: inherit;
    transition: ${theme.transitions.create(['opacity'])};
    background: ${darken(alpha(theme.colors.primary.main, 0.9), 0.8)};
    z-index: 6;
    opacity: 0;
    box-shadow: inset 0 0 2.3rem 0.5rem ${darken(
      theme.colors.primary.main,
      0.9
    )};
  `
);

const ButtonWrapper = styled(Button)(
  ({ theme }) => `
        transform: translateY(0px);
        transition: ${theme.transitions.create(['color', 'transform'])};

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }
        p{
 transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
}

        &:hover {
            transform: translateY(-2px);
    
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
  p{
 transform: scale(1.1);
}
        }
  `
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        transition: ${theme.transitions.create(['color', 'transform'])};
        border-radius: 100px;

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `
);

export default function PreviewCardV2(props: {
  card: DigitalCard;
  fieldsList: DigitalCardField[];
  vCard:vCardType | null;
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();

  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openConnectDialog, setOpenConnectDialog] = useState(false);
  const [isSaveContactInProgreess, setIsSaveContactInProgreess] =
    useState(false);
  const [preFields, setPreFields] = useState<PreField[]>([]);
  const [listItemFields, setListItemFields] = useState<PreField[]>([]);
  const convertedPreFields = convertCardFieldsToPreFields(
    props.fieldsList,
    props.card.fields
  );
  const info = parseCardFieldInfo(props.card, props.fieldsList);

  const fieldValues = props.card.fields;

  useEffect(() => {
    const fields = _.sortBy([...convertedPreFields], (o) => o.order);

    //set ids
    const grouped = _.groupBy(fields, (o) => o.fieldName);
    _.keys(grouped).forEach((k) => {
      const items = grouped[k];
      if (items) {
        items.forEach((item, i) => {
          item.id = item.fieldName + '_' + (i + 1);
        });
      }
    });

    setPreFields(fields);
  }, []);

  const fieldHash = useMemo(() => {
    const fieldHash: Dictionary<PreField> = {};
    preFields.forEach((o) => (fieldHash[o.fieldName] = o));
    return fieldHash;
  }, [preFields]);

  const socialFields = useMemo(
    () => props.fieldsList.filter((o) => socialFieldNames.includes(o.name)),
    [props.fieldsList]
  );
  const socialFields1 = useMemo(
    () =>
      _.sortBy(
        preFields.filter((o) => socialFieldNames.includes(o.fieldName)),
        (o) => o.order
      ),
    [preFields]
  );
  const fixedFields = useMemo(() => getFixedFields(fieldHash), [preFields]);

  useEffect(() => {
    let items = _.differenceWith(
      preFields,
      fixedFields,
      (one, two) => one.fieldName == two.fieldName
    );
    items = _.differenceWith(
      items,
      socialFields1,
      (one, two) => one.fieldName == two.fieldName
    );
    items = _.sortBy(items, (o) => o.order);
    setListItemFields(items);
  }, [preFields]);

  const onCardActionClick = async (e: CardActionItem) => {
    const config = parseMyJson(e.config, {});
    if (!config?.handler) {
      console.log('config handler not defined');
    } else {
      const callback = (cardActionHandlerRegistry as any)[config.handler];
      if (callback) {
        await callback();
      }
    }
  };

  const onSaveContact = async () => {
   
    if(isSaveContactInProgreess){
      return;
    }

    try {
     
     
      setIsSaveContactInProgreess(true);

      // const bodyData = (document?.getElementById('clientData') as any).value;

      // const res = await fetch(window.location.origin + '/api/vc', {
      //   method: 'POST',
      //   body: bodyData,
      //   headers: { 'Content-type': 'application/json; charset=UTF-8' },
      // });
      if(props.vCard){

      const result = props.vCard;
       
      const linkElement = document.createElement('a');
      linkElement.setAttribute(
        'href',
        'data:text/vcard;charset=utf-8,' + encodeURIComponent(result.data)
      );
      linkElement.setAttribute('download', result.name + '.vcf');
      linkElement.style.display = 'none';
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
      }
      setIsSaveContactInProgreess(false);

    } catch (ex) {
      setIsSaveContactInProgreess(false);
    }
  };
  const onConnect = async () => {
    setOpenConnectDialog(true);
  };
  const onShare = async () => {
    if (!mobile) {
      setOpenShareDialog(true);
      return;
    }
    if (navigator.share) {
      navigator
        .share({
          title: 'Share kiengage profile',
          url: `${window.location.origin}/app/viewer/${props.card.id}`,
        })
        .then(() => {
          enqueueSnackbar(`Thanks for sharing!`, { variant: 'success' });
        })
        .catch(console.error);
    } else {
      // fallback
      setOpenShareDialog(true);
    }
  };

  const onMsg = async () => {
    
    let matches = props.card.fields.filter(
      (o) => o.fieldName == CardFieldNames.ContactNumber
    );

   let contactNumber='';

    if (matches.length) {
      contactNumber = (first(matches[0].formFieldValues)?.value || '')?.trim();
    }
    if(!contactNumber){
      enqueueSnackbar(`Contact number is missing`, { variant: 'warning' });
    }else{

      if(contactNumber.length<=10 && !contactNumber.startsWith('+')){
        contactNumber='+1'+contactNumber;
      }

      let msg='Hi '
      if (props.card.cardType == CardTypes.Individual) {
        const match = props.card.fields.find((o) => o.fieldName == CardFieldNames.FullName);
        if (match) {
          msg  = msg +  first(match.formFieldValues)?.value+', ';
        }
      }else{
        let match = props.card.fields.find(
          (o) => o.fieldName == CardFieldNames.BusinessName
        );
        if (match) {
          msg  = msg +  first(match.formFieldValues)?.value+', ';
        }
      }
      const a = document.createElement('a');
      a.href =  'sms:+'+contactNumber+'?&body='+msg;  
      a.style.display='none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
   

    
  }
  const cardActionHandlerRegistry = {
    onSaveContact: onSaveContact,
    onConnect: onConnect,
    onShare: onShare,
    onMsg:onMsg
  };

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
  };
  const handleCloseConnectDialog = () => {
    setOpenConnectDialog(false);
  };

  const getBgStyle = () => {
    if (props.card?.theme == 'black') {
      return {
        boxShadow:
          '0px 9px 16px rgb(159 162 191 / 18 %), 0px 2px 2px rgb(159 162 191 / 32 %)',
        overflow: 'hidden',
        background: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
        color: '#fff',
      };
    } else if (props.card?.theme == 'blue') {
      return {
        boxShadow:
          '0px 9px 16px rgb(159 162 191 / 18 %), 0px 2px 2px rgb(159 162 191 / 32 %)',
        overflow: 'hidden',
        background: 'linear-gradient(135deg, #7c80ce 0%, #2b2e5f 100%)',
        color: '#fff',
      };
    }
    return {};
  };

  const getTypoStyle = () => {
    if (props.card?.theme == 'black') {
      return {
        color: '#fff',
      };
    } else if (props.card?.theme == 'blue') {
      return {
        color: '#fff',
      };
    }
    return {};
  };
  const renderNewFieldBlock = (e: PreField) => {
    const inputProp: FieldRenderBlockBaseProp = {
      field: e,
      isReadOnly: false,
      onClick: () => {},
      themeColor: props.card?.theme || '',
      address: info.address || '',
      isBusinessCard: info.isBusinessCard,
    };
    if (
      e.fieldName === CardFieldNames.ImageSlider ||
      e.fieldName === CardFieldNames.MediaPlayer
    ) {
      const val = e.fieldValues.find((o) => o.name == 'config');
      return FieldsRegistry[e.fieldName].renderBlock(
        JSON.parse(val?.value as string)
      );
    }

    return FieldsRegistry[e.fieldName].renderBlock(inputProp);
  };

  return (
    <>
      <Head>
        <title>{`${info.name || 'Profile'}@Kiengage`}</title>
      </Head>
      <Card
        sx={{
          ...{
            pt: 2,
            textAlign: 'center',
          },
          ...getBgStyle(),
        }}
      >
        <Box
          sx={{
            mx: 1,
            position: 'relative',
            transition: `${theme.transitions.create([
              'box-shadow',
              'transform',
              'border-radius',
            ])}`,
            transform: 'translateY(0px)',
            borderRadius: 'inherit',
          }}
        >
          <BgComposed
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="MuiBgComposed"
          >
            <IconButtonWrapper
              sx={{
                mx: 0.5,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main
                )}`,

                '&:hover': {
                  background: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main
                  )}`,
                },
              }}
            >
              <FacebookIcon fontSize="small" />
            </IconButtonWrapper>
            <IconButtonWrapper
              sx={{
                mx: 0.5,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main
                )}`,

                '&:hover': {
                  background: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main
                  )}`,
                },
              }}
            >
              <TwitterIcon fontSize="small" />
            </IconButtonWrapper>
          </BgComposed>
          {info.coverPhoto && (
            <CardMedia
              component="img"
              sx={{
                borderRadius: 'inherit',
                position: 'relative',
                zIndex: 5,
              }}
              image={cdnService.toImageUrl(info.coverPhoto)}
              alt="..."
            />
          )}
        </Box>
        <Box p={2}>
          <Avatar
            sx={{
              width: `${theme.spacing(14)}`,
              height: `${theme.spacing(14)}`,
              // mt: info.coverPhoto ? `-${theme.spacing(10)}` : 0,
              mx: 'auto',
              boxShadow: `0 0 0 4px ${theme.colors.alpha.white[100]}`,
              borderRadius: `${info.isBusinessCard ? '10px' : ''}`,
            }}
            src={info.profilePic ? cdnService.toImageUrl(info.profilePic) : ''}
          />
          <Typography
            sx={{
              py: 1,
            }}
            variant="h3"
            style={getTypoStyle()}
          >
            {info.isBusinessCard ? info.businessName : info.name}
          </Typography>
          {(info.jobTitle || info.companyName) && (
            <Typography
              sx={{
                px: 0,
              }}
              variant="subtitle2"
              textAlign="center"
              style={getTypoStyle()}
            >
              {info.jobTitle +
                ' ' +
                (info.companyName
                  ? (info.jobTitle ? ' at ' : ' ') + info.companyName
                  : '')}
            </Typography>
          )}
          {info.department && (
            <Typography
              sx={{
                px: 0,
              }}
              variant="subtitle2"
              textAlign="center"
              style={getTypoStyle()}
            >
              {info.department}
            </Typography>
          )}
          {info.headline && (
            <Typography
              sx={{
                px: 0,
                my: 1,
              }}
              variant="subtitle2"
              textAlign="center"
              style={getTypoStyle()}
            >
              {info.headline}
            </Typography>
          )}

          {props.card.cardActionItems &&
            props.card.cardActionItems.length > 0 && (
              <>
                <Divider />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={0.5}
                  sx={{
                    background: `${theme.colors.alpha.black[5]}`,
                  }}
                >
                  <CardActionButtonGroup
                    isSaving={isSaveContactInProgreess}
                    cardActionItems={props.card.cardActionItems}
                    onClick={onCardActionClick}
                  />
                </Box>
                <Divider />
              </>
            )}

          <Box mt={2} mx={0}>
            {socialFields.length > 0 && (
              <Box>
                {socialFields.map((field) => (
                  <Tooltip
                    key={field.id}
                    arrow
                    placement="top"
                    title={field.displayName}
                  >
                    <IconButton
                      sx={{
                        background: `${theme.colors.primary.lighter}`,
                        width: 54,
                        height: 54,
                        mr: 1,
                        color: `${theme.colors.primary.main}`,
                        '&:hover': {
                          background: `${alpha(
                            theme.colors.primary.main,
                            0.2
                          )}`,
                          color: `${theme.colors.primary.main}`,
                        },
                      }}
                      color="primary"
                      size="large"
                      onClick={() => {
                        const link =
                          fieldValues
                            .find((o) => o.fieldId == field.id)
                            ?.formFieldValues.find((o) => o.name == field.name)
                            ?.value || '';
                        if (!link.trim()) {
                          return;
                        }
                        (window as any).open(
                          addHttpPrefixToUrl(link),
                          '_blank'
                        );
                      }}
                    >
                      <img
                        src={cdnService.toCdnUrl(field.icon as string, 'icons')}
                        height={25}
                        width={25}
                        alt={field.displayName}
                      />
                    </IconButton>
                  </Tooltip>
                ))}
              </Box>
            )}
            <List sx={{ width: '100%', marginTop: 2 }} disablePadding>
              {listItemFields.map(
                (field) => isNewField(field) && renderNewFieldBlock(field)
              )}
            </List>
          </Box>
          <DialogWrapper
            open={openShareDialog}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseShareDialog}
          >
            <Box
              sx={{
                px: 4,
                pb: 4,
                pt: 10,
              }}
            >
              <AvatarShareDialog>
                <ShareIcon />
              </AvatarShareDialog>

              <Collapse in={true}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        //setOpenAlert(false);
                      }}
                    ></IconButton>
                  }
                  severity="info"
                >
                  Card details will be shared on email you enter below
                </Alert>
              </Collapse>

              <Typography
                align="center"
                sx={{
                  py: 2,
                  px: 10,
                }}
                variant="h3"
              >
                {'Enter email to share with'}
              </Typography>
              <ShareForm
                cardId={props.card.id}
                onSave={handleCloseConnectDialog}
              />
            </Box>
          </DialogWrapper>

          <DialogWrapper
            open={openConnectDialog}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseConnectDialog}
          >
            <IconButton
              sx={{ position: 'absolute', right: '0px' }}
              onClick={handleCloseConnectDialog}
            >
              <CloseIcon />
            </IconButton>

            <Box
              sx={{
                px: 4,
                pb: 4,
                pt: 4,
              }}
            >
             

              <Typography
                align="center"
                sx={{
                  py: 1,
                  px: 2,
                }}
                variant="h3"
              >
                {'Share your info'}
              </Typography>

              <ConnectForm
                cardId={props.card.id}
                onSave={handleCloseConnectDialog}
              />


                <Alert
                sx={{mt:1}}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        //setOpenAlert(false);
                      }}
                    ></IconButton>
                  }
                  severity="info"
                >
                  Kiengage does not sell or share your data
                </Alert>
             
            </Box>
          </DialogWrapper>
        </Box>
      </Card>
    </>
  );
}
