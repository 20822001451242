const splitFullname = (fullname: string) => {
    const part = { firstName: '', lastName: '', middleName: '' };

    if (!fullname)
        return part;



    if (!fullname.includes(' ')) {
        part.firstName = fullname;
    } else {
        const parts = fullname.split(' ');
        if (parts.length == 2) {
            part.firstName = parts[0];
            part.lastName = parts[1];
        } else {
            part.firstName = parts[0];
            part.middleName = parts[1];
            part.lastName = parts.slice(1, parts.length - 1).join(' ');
        }
    }
    return part;
}

export default splitFullname;