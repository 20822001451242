import axios, { AxiosResponse } from "axios";
import { ApiUrls } from "../endpoints";
import {
  CardFieldCategory,
  CardPathItem,
  ConnectRequest,
  DigitalCard,
  LeadRequest,
  NfcCodeMap,
  SelfReview,
  ShareContactRequest,
} from "./responseModels";

const fetchConfig: RequestInit = {
  headers: {
    ApiKey: "",
  },
};

export const getAllCardFieldCategories = (): Promise<
  AxiosResponse<CardFieldCategory[]>
> => {
  const dataUrl =
    ApiUrls.clientApiEndpoint + "/digital-card/field/category/all";
  return axios.get(dataUrl);
};

export const getCardById = async (
  cardId: number
): Promise<AxiosResponse<DigitalCard>> => {
  // const response = await fetch(ApiUrls.clientApiEndpoint + "/digital-card/id/" + cardId, fetchConfig);
  return axios.get(ApiUrls.clientApiEndpoint + "/digital-card/id/" + cardId);
};

export const getCardByNameOrId = async (
  cardIdOrName: string | number
): Promise<AxiosResponse<DigitalCard>> => {
  return axios.get(
    ApiUrls.clientApiEndpoint + "/digital-card/name/" + cardIdOrName
  );
};
export const saveLeadsFormByCardId = (
  request: LeadRequest
): Promise<AxiosResponse<void>> => {
  return axios.post(ApiUrls.clientApiEndpoint + "/lead", request);
};

export const getNfcCodeMap = (
  nfcCode: string
): Promise<AxiosResponse<NfcCodeMap>> => {
  return axios.get(
    ApiUrls.clientApiEndpoint +
      "/nfc-map/look-up?nfcCode=" +
      encodeURIComponent(nfcCode) +
      "&overrideByActiveCard=true"
  );
};

export const saveConnect = (
  request: ConnectRequest
): Promise<AxiosResponse<void>> => {
  return axios.post(ApiUrls.clientApiEndpoint + "/connect", request);
};

export const shareProfile = (
  request: ShareContactRequest
): Promise<AxiosResponse<void>> => {
  return axios.post(ApiUrls.clientApiEndpoint + "/share-profile", request);
};

export const saveSelfReview = (
  request: SelfReview
): Promise<AxiosResponse<void>> => {
  return axios.post(ApiUrls.clientApiEndpoint + "/self-review", request);
};


export const getAllCardPathItems = (): Promise<
  AxiosResponse<CardPathItem[]>
> => {
  const dataUrl =
    ApiUrls.clientApiEndpoint + "/card/path-items";
  return axios.get(dataUrl);
};
