import _ from "lodash";
import { CardFieldNames, CardTypes, DigitalCard, DigitalCardField } from "../services/apiService/responseModels";
import { getTitleDesc } from "./getTitleDesc";
import { getValueDesc } from "./getValueDesc";
import splitFullname from "./splitFullname";

function parseCardFieldInfo(card: DigitalCard, fieldsList: DigitalCardField[]): CardFlatInfo {
    const info: CardFlatInfo = {
        cardId: card.id,
        clientAccountId: card.clientAccountId,
        cardLabel: card.label,
        isBusinessCard: card.cardType == CardTypes.Business,
        name: '',
        firstName:'',
        middleName:'',
        lastName: '',
    }as any;

    if (info.isBusinessCard) {
        info.name = info.businessName = _.first(getFlatValues(card, fieldsList, CardFieldNames.BusinessName))?.value || '';
    } else {
        info.name = _.first(getFlatValues(card, fieldsList, CardFieldNames.FullName))?.value || '';
        const names = splitFullname(info.name);
        info.firstName = names.firstName;
        info.middleName = names.middleName;
        info.lastName = names.lastName;
    }

    info.profilePic = _.first(getFlatValues(card, fieldsList, CardFieldNames.ProfilePic))?.value || '';
    info.coverPhoto = _.first(getFlatValues(card, fieldsList, CardFieldNames.CoverPhoto))?.value || '';
    info.headline = _.first(getFlatValues(card, fieldsList, CardFieldNames.Headline))?.value || '';

    info.companyName = _.first(getFlatValues(card, fieldsList, CardFieldNames.CompanyName))?.value || '';
    info.department = _.first(getFlatValues(card, fieldsList, CardFieldNames.Department))?.value || '';
    info.jobTitle = _.first(getFlatValues(card, fieldsList, CardFieldNames.JobTitle))?.value || '';
    info.address = _.first(getFlatValues(card, fieldsList, CardFieldNames.Address))?.value || '';

    info.accreditations = getFlatValues(card, fieldsList, CardFieldNames.Accreditation);
    info.websites = getFlatValues(card, fieldsList, CardFieldNames.Website);
    info.links = getFlatValues(card, fieldsList, CardFieldNames.Link);
    info.emails = getFlatValues(card, fieldsList, CardFieldNames.Email);
    info.contactNumbers = getFlatValues(card, fieldsList, CardFieldNames.ContactNumber);
   
    info.facebook = _.first(getFlatValues(card, fieldsList, CardFieldNames.Facebook))?.value || '';
    info.twitter = _.first(getFlatValues(card, fieldsList, CardFieldNames.Twitter))?.value || '';
    info.instagram = _.first(getFlatValues(card, fieldsList, CardFieldNames.Instagram))?.value || '';
    info.linkedIn = _.first(getFlatValues(card, fieldsList, CardFieldNames.LinkedIn))?.value || '';
    info.youtube = _.first(getFlatValues(card, fieldsList, CardFieldNames.YouTube))?.value || '';
    info.snapchat = _.first(getFlatValues(card, fieldsList, CardFieldNames.Snapchat))?.value || '';
    info.twitch = _.first(getFlatValues(card, fieldsList, CardFieldNames.Twitch))?.value || '';
    info.whatsApp = _.first(getFlatValues(card, fieldsList, CardFieldNames.WhatsApp))?.value || '';
    info.signal = _.first(getFlatValues(card, fieldsList, CardFieldNames.Signal))?.value || '';
    info.discord = _.first(getFlatValues(card, fieldsList, CardFieldNames.Discord))?.value || '';
    info.skype = _.first(getFlatValues(card, fieldsList, CardFieldNames.Skype))?.value || '';
    info.telegram = _.first(getFlatValues(card, fieldsList, CardFieldNames.Telegram))?.value || '';
    info.gitHub = _.first(getFlatValues(card, fieldsList, CardFieldNames.GitHub))?.value || '';
    info.calendly = _.first(getFlatValues(card, fieldsList, CardFieldNames.Calendly))?.value || '';
    info.payPal = _.first(getFlatValues(card, fieldsList, CardFieldNames.PayPal))?.value || '';
    info.cashApp = _.first(getFlatValues(card, fieldsList, CardFieldNames.CashApp))?.value || '';
    info.appleStore = _.first(getFlatValues(card, fieldsList, CardFieldNames.AppleStore))?.value || '';
    info.appStore = _.first(getFlatValues(card, fieldsList, CardFieldNames.AppStore))?.value || '';

    return info;
}

const getFlatValues = (card: DigitalCard, fieldsList: DigitalCardField[], fname: CardFieldNames): FlatValue[] => {

    const results: FlatValue[] = [];

    const cardFieldValues = card.fields.filter(o => o.fieldName == fname);
    const field = fieldsList.find(o => o.name == fname);
    if (field) {
        cardFieldValues.forEach(cardVal => {
            results.push({
                title: getTitleDesc(field, cardVal),
                value: getValueDesc(field, cardVal.formFieldValues),
                order: cardVal.order
            });
        });
    }
    
    return results;
}
export interface CardFlatMetadata {
    cardId: number;
    cardLabel: string;
    clientAccountId: string;
}
export interface CardFlatInfo {
    metaData: CardFlatMetadata;
    isBusinessCard: boolean;
    name: string;
    businessName: string;
    companyName: string;
    jobTitle: string;
    department: string;
    headline: string;
    profilePic: string;
    coverPhoto: string;
    firstName: string;
    middleName: string;
    lastName: string;
    address: string;
    facebook: string;
    twitter: string;
    signal: string;
    instagram: string;
    discord: string;
    whatsApp: string;
    skype: string;
    linkedIn: string;
    snapchat: string;
    youtube: string;
    telegram: string;
    appStore: string;
    twitch: string;
    gitHub: string;
    calendly: string;
    payPal: string;
    cashApp: string;
    appleStore: string;
    websites: FlatValue[];
    links: FlatValue[];
    accreditations: FlatValue[];
    emails: FlatValue[];
    contactNumbers: FlatValue[];
}
    export interface FlatValue {
        title?: string;
        value: string;
        order: number;
    }


export default parseCardFieldInfo;

