const ApiBaseUrl = process.env.NEXT_PUBLIC_API_URL || '';
// const ApiBaseUrl = process.env.NEXT_PUBLIC_LOCAL_API_URL || '';

export const ApiUrls = {
  base: ApiBaseUrl,
  accountEndpoint: ApiBaseUrl + '/api/v1/account',
  manageCardEndpoint: ApiBaseUrl + '/api/v1/manage-card',
  clientApiEndpoint: ApiBaseUrl + '/api/v1/client-api',
  membershipEndpoint: ApiBaseUrl + '/api/v1/membership',
  appKieazyUrl: process.env.NEXT_PUBLIC_APP_KIEAZY_URL,
  viewerKieazyUrl: process.env.NEXT_PUBLIC_VIEWER_KIEAZY_URL,
  nestJsApi: process.env.NEXT_PUBLIC_APP_CARD_API_NEST || "",
  SandBoxApiUrl: process.env.SANDBOX_API_URL
};
