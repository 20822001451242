export const addHttpPrefixToUrl = (url: string) => {
  const lowerCaseUrl = url.toLocaleLowerCase();
  if (
    lowerCaseUrl.startsWith("http://") ||
    lowerCaseUrl.startsWith("https://")
  ) {
    return url;
  }
  return `http://${url}`;
};
