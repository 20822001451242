import { Button, Card, CircularProgress, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { TextField as FmTextFiled } from 'formik-mui';
import * as Yup from 'yup';
import { shareProfile } from "../../services/apiService";
import { ShareContactRequest } from "../../services/apiService/responseModels";
import { useSnackbar } from "notistack";

function ShareForm(props: { cardId: number, onSave: () => void }) {
    const { enqueueSnackbar } = useSnackbar();
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [error, setError] = useState('');

    const onSave = async (updatedModel: ShareContactRequest) => {
        let isSuccess = false;
        try {
            setIsSaveInProgress(true);
            await shareProfile(updatedModel);
            enqueueSnackbar(
                `Thanks for sharing`,
                { variant: 'success' }
            );
            isSuccess = true;
            setIsSaveInProgress(false);
            props.onSave();
        } catch (ex) {
            setIsSaveInProgress(false);
            console.log(ex);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
        return isSuccess;
    };

    return (<Formik
        initialValues={{
            contactName: "",
            contactEmail: "",
        }}
        validationSchema={Yup.object().shape({
            contactEmail: Yup.string()
                .max(250)
                .required("The field is required")
                .email("Not a proper email"),
        })}
        onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
            try {
                if (isSaveInProgress) {
                    return;
                }
                const isSuccess = await onSave({ ..._values, cardId: props.cardId });
                if (isSuccess) {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                }
            } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setSubmitting(false);
            }
        }}
    >
        {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue,
        }) => (
            <Form noValidate onSubmit={handleSubmit}>

                <Card sx={{ mb: 2, p: 2 }}>
                    <Grid container spacing={3}>
                        {/*<Grid item xs={12}>*/}
                        {/*    <Field*/}
                        {/*        fullWidth*/}
                        {/*        name="contactName"*/}
                        {/*        variant="outlined"*/}
                        {/*        label={'Full name'}*/}
                        {/*        placeholder={'Enter your name here ...'}*/}
                        {/*        component={FmTextFiled}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <Field
                                fullWidth
                                name="contactEmail"
                                variant="outlined"
                                label={'Email'}
                                placeholder={'Enter your email here ...'}
                                component={FmTextFiled}
                            />
                        </Grid>
                    </Grid>
                </Card>

                <Button
                    fullWidth
                    type="submit"

                    size="medium"
                    variant="contained"
                >
                    {isSubmitting || isSaveInProgress ? 'Please wait...' : 'Share'}
                </Button>
            </Form>
        )}
    </Formik>)
}

export default ShareForm;