import {
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import cdnService from "../../../services/cdnService";
import { addHttpPrefixToUrl } from "../../../utils/addHttpPrefixToUrl";
import FieldRenderBlockBaseProp from "../_base/FieldRenderBlockBaseProp";
import {
  getPreFieldTitleDesc,
  getPreFieldValueDesc,
  getTypoStyle,
  ListItemWrapper,
} from "../_base/StyledComponent";

export default function SkypeBlock(props: FieldRenderBlockBaseProp) {
  const theme = useTheme();
  const title = getPreFieldTitleDesc(props.field);
  const value = getPreFieldValueDesc(props.field);
  return (
    <>
      <ListItemWrapper
        onClick={() => {
          const link = addHttpPrefixToUrl(value);
          window.open(link, "_blank");
        }}
        sx={{
          minWidth: 0,
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <ListItemAvatar
          sx={{
            mr: 1,
            display: "flex",
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              background: `${theme.colors.alpha.white[10]}`,
              color: `${theme.colors.gradients.pink2}`,
              width: 64,
              height: 64,
            }}
          >
            <img src={cdnService.toCdnUrl("skype.png", "icons")} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{
            color: `${theme.colors.alpha.black[70]}`,
          }}
          primary={
            <Typography
              gutterBottom
              variant="h4"
              style={getTypoStyle(props.themeColor)}
            >
              {value}
            </Typography>
          }
        />
      </ListItemWrapper>
      <Divider />
    </>
  );
}
