import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  styled,
  Box,
  useTheme,
  alpha,
  CardMedia,
  CardActions,
  Link,
  Typography,
  Button,
  CardActionArea,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";

import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import ChevronLeftTwoToneIcon from "@mui/icons-material/ChevronLeftTwoTone";
import ReactPlayer from "react-player/youtube";
import { ListItemWrapper } from "../_base/StyledComponent";

const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(5)};
        height: ${theme.spacing(5)};
        border-radius: 100px;
        transition: ${theme.transitions.create(["all"])};

        &:hover {
          color: ${theme.colors.alpha.black[100]};
          background: ${theme.colors.primary.lighter};
        }
`
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.info.main};
  color: ${theme.palette.info.contrastText};
  text-transform: uppercase;
  font-size: ${theme.typography.pxToRem(10)};
  font-weight: bold;
  line-height: 23px;
  height: 22px;
  padding: ${theme.spacing(0, 1.2)};
  border-radius: 50px;
`
);

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);

export interface MediaPlayerType {
  title?: string;
  subTitle?: string;
  url: string;
}

export default function MediaPlayerBlock(props: MediaPlayerType) {
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild || !props.url) return <></>;

  return (
    <ListItemWrapper
      sx={{
        minWidth: 0,
      }}
    >
      <Card
        sx={{
          overflow: "hidden",
          textAlign: "left",
          border: "solid 1px #ccc",
          background: "inherit",
          color: "inherit",
          width: "100%",
        }}
      >
        {(props.title || props.subTitle) && (
          <CardHeader
            sx={{ pb: 1, color: "inherit" }}
            title={props.title}
            subheader={props.subTitle}
          />
        )}
        <Box px={0} pb={0} height={"200px"}>
          <ReactPlayer
            height={"200px"}
            width={"100%"}
            url={props.url}
            controls
          />
        </Box>
      </Card>
    </ListItemWrapper>
  );
}

MediaPlayerBlock.defaultProps = {
  url: "",
};
