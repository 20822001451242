import { CardFieldValue, DigitalCardField} from "../services/apiService/responseModels";

export const getTitleDesc = (field: DigitalCardField, fieldValue?: CardFieldValue): string => {
    if (!fieldValue)
        return '';

    if (!field.titleDescriptor)
        return field.displayName;

    //repalce all '{Title} ' with title value
    let title = field.titleDescriptor;
    field.formFields?.forEach(o => {
        const value = fieldValue.formFieldValues.find(fv => fv.name == o.name)?.value || '';
        title = title.replaceAll(new RegExp("\{" + o.name + "\}", "gi"), value);
    });

    return title;
}