import { alpha, ListItem, styled } from "@mui/material";
import { CardFieldNames } from "../../../services/apiService/responseModels";
import PreField from "../../../services/apiService/responseModels/PreField";

export const ListItemWrapper = styled(ListItem)(
  ({ theme }) => `
        position: relative;
        transition: ${theme.transitions.create(["background"])};
  
        .MuiActionButtons {
              background: ${alpha(theme.colors.alpha.white[100], 0.95)};
              border-radius: ${theme.general.borderRadius};
              opacity: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              visibility: hidden;
              top: 50%;
              margin-top: -${theme.spacing(3.5)};
              position: absolute;
              right: ${theme.spacing(1.5)};
              padding: ${theme.spacing(0.5, 1)};
              transition: ${theme.transitions.create([
                "visibility",
                "opacity",
              ])};
  
              .MuiIconButton-root {
                  border-radius: 100px;
                  width: ${theme.spacing(5)};
                  height: ${theme.spacing(5)};
                  margin: ${theme.spacing(0.5)};
              }
        }
  
        &:hover {
            background: ${alpha(theme.colors.secondary.main, 0.15)};
  
            .MuiActionButtons {
              visibility: visible;
              opacity: 1;
        }   
        }
    `
);

export const getTypoStyle = (theme: string) => {
  if (theme == "black") {
    return {
      color: "#fff",
    };
  } else if (theme == "blue") {
    return {
      color: "#fff",
    };
  }
  return {};
};

export const isNewField = (e: PreField): boolean => {
  if (
    e.fieldName == CardFieldNames.ContactNumber ||
    e.fieldName == CardFieldNames.Email ||
    e.fieldName == CardFieldNames.Website ||
    e.fieldName == CardFieldNames.Link ||
    e.fieldName == CardFieldNames.WhatsApp ||
    e.fieldName == CardFieldNames.Signal ||
    e.fieldName == CardFieldNames.Discord ||
    e.fieldName == CardFieldNames.Skype ||
    e.fieldName == CardFieldNames.Telegram ||
    e.fieldName == CardFieldNames.GitHub ||
    e.fieldName == CardFieldNames.Calendly ||
    e.fieldName == CardFieldNames.PayPal ||
    e.fieldName == CardFieldNames.CashApp ||
    e.fieldName == CardFieldNames.AppleStore ||
    e.fieldName == CardFieldNames.AppStore ||
    e.fieldName == CardFieldNames.MediaPlayer ||
    e.fieldName == CardFieldNames.ImageSlider ||
    e.fieldName == CardFieldNames.Address || 
    e.fieldName == CardFieldNames.GoogleReview
  )
    return true;
  else return false;
};

export const getPreFieldTitleDesc = (field: PreField): string => {
  if (!field.fieldValues || !field.fieldValues.length) return "";

  if (!field.fieldRef.titleDescriptor) return field.fieldRef.displayName;

  //repalce all '{Title} ' with title value
  let title = field.fieldRef.titleDescriptor;
  (field?.fieldRef?.formFields || []).forEach((o) => {
    const value =
      field.fieldValues.find((fv) => fv.name == o.name)?.value || "";
    title = title.replaceAll(new RegExp("{" + o.name + "}", "gi"), value);
  });

  return title;
};

export const getPreFieldValueDesc = (field: PreField): string => {
  if (!field.fieldValues || !field.fieldValues.length) return "";

  if (!field.fieldRef.valueDescriptor)
    return (
      field.fieldValues.find((o) => o.name == field.fieldName)?.value || ""
    );

  //repalce all '{Title} ' with title value
  let label = field.fieldRef.valueDescriptor;
  (field?.fieldRef?.formFields || []).forEach((o) => {
    const value =
      field.fieldValues.find((fv) => fv.name == o.name)?.value || "";
    label = label.replaceAll(new RegExp("{" + o.name + "}", "gi"), value);
  });

  return label;
};
