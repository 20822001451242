import { Dictionary } from "lodash";

export interface ClientAccount {
  id: number;
  name: string;
  description: string;
  tenantCode: string;
  accountType: AccountTypes;
  businessAccounts: BusinessAccount[];
  isActive: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export enum AccountTypes {
  Business = "Business",
  Individual = "Individual",
  Any = "Any",
}

export enum CardTypes {
  Business = "Business",
  Individual = "Individual",
  Service = "Service",
  All = "All",
}

export interface BusinessAccount {
  id: number;
  name: string;
  description?: string;
  businessCategory?: string;
  logoUrl?: string;
  imageUrl?: string;
  dateStarted?: Date;
  website?: string;
  tenantCode: string;
  accountType: AccountTypes;
  contacts: Contact[];
  contactNumbers: ContactNumber[];
  contactEmails: ContactEmail[];
  addresses: Address[];
  socialMediaLinks: SocialMediaLink[];
  isActive: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface ContactNumber {
  id: number;
  name: string;
  isPrimary: boolean;
  //Work Number, Personal, Fax
  contactType: string;
  countryCode: string;
  number: string;
  exNumber: string;
  isActive: boolean;
}

export interface ContactEmail {
  id?: number;
  email: string;
  isPrimary?: boolean;
  EmailType?: EmailTypes;
  isActive?: boolean;
}
export enum EmailTypes {
  Personal = "Personal",
  Work = "Work",
  Alternate = "Alternate",
}

export interface Address {
  id?: number;
  name?: string;
  isPrimary?: boolean;
  addressType?: string;
  addressLine?: string;
  addressLine2?: string;
  city?: string;
  cityCode?: string;
  state?: string;
  stateCode?: string;
  country?: string;
  countryCode?: string;
  zip?: string;
  geoLoc?: GeoLoc;
  isActive?: boolean;
}
export interface SocialMediaProvider {
  id: number;
  name: string;
  category: string;
  logo: string;
  url: string;
  isActive: boolean;
}

export interface SocialMediaLink {
  id: number;
  socialMediaProviderId: number;
  link: string;
}

export interface Contact {
  id: number;
  businessAccountId: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  title: string;
  fullName: string;
  pronouns: string;
  description: string;
  descriptionRich: string;
  imageUrl: string;
  website: string;
  department: string;
  dateOfBirth?: Date;
  jobTitlte: string;
  accreditations?: Accreditation[];
  contactEmails?: ContactEmail[];
  contactNumbers?: ContactNumber[];
  socialMediaLinks?: SocialMediaLink[];
  addresses?: Address[];
  isActive: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface GeoLoc {
  id: number;
  lat: string;
  lng: string;
}

export interface Accreditation {
  id: number;
  name: string;
  description: string;
  logoUrl?: string;
}

export interface BusinessCategory {
  id: number;
  name: string;
  description: string;
  featureImage: string;
  isActive: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface CodeItem {
  codeId: number;
  value: string;
  codeType?: string;
}

//card instance
export interface DigitalCard {
  id: number;
  clientAccountId: number;
  label: string;
  description: string;
  cardType: CardTypes;
  parentId: number;
  fields: CardFieldValue[];
  qrCode?: string;
  theme?: string;
  nfc?: NfcInfo;
  isOnline: boolean;
  isActive: boolean;
  cardAppItems: CardAppItem[];
  cardActionItems?: CardActionItem[];
}

export interface NfcInfo {
  code: string;
}

export interface QRInfo {
  code: string;
  imageUrl: string;
}

export interface CardFieldValue {
  id: string;
  fieldId: number;
  fieldName: string;
  formFieldValues: FormFieldValue[];
  order: number;
}

export interface FormFieldValue {
  name: string;
  value: string;
}

export interface KeyValueItem {
  key: string;
  value: string;
}
export interface DigitalCardField {
  id: number;
  cardType: CardTypes;
  categoryId: number;
  categoryName: string;
  name: string;
  displayName: string;
  description?: string;
  icon?: string;
  order?: number;
  formFields?: CardFormField[];
  multiple?: boolean;
  valueFieldName?: string;
  valueDescriptor?: string;
  titleDescriptor?: string;
}

export interface CardFormField {
  id: number;
  digitalCardFieldId: number;
  name: string;
  displayName: string;
  helpText?: string;
  icon?: string;
  isRequired?: boolean;
  dataType: FieldDataType;
  props: string | any;
  options: string | any;
  validationKey?: string;
  defaultValue?: string;
  order?: number;
}

export enum FieldDataType {
  Text = "Text",
  Number = "Number",
  Date = "Date",
  Time = "Time",
  Link = "Link",
  TextArea = "TextArea",
  Radio = "Radio",
  File = "File",
  Image = "Image",
  Button = "Button",
  DropDown = "DropDown",
  DropDownMultiSelection = "DropDownMultiSelection",
  ComboBox = "ComboBox",
  RichTextArea = "RichTextArea",
  Password = "Password",
}

export interface CardFieldCategory {
  id: number;
  name: string;
  displayName: string;
  description: string;
  icon: string;
  order: number;
  isActive: boolean;
  cardType: CardTypes;
  digitalCardFields: DigitalCardField[];
}

export enum CardFieldCategoryNames {
  PersonalDetails = "PersonalDetails",

  BusinessDetails = "BusinessDetails",

  Contact = "Contact",

  Social = "Social",

  Communication = "Communication",

  Business = "Business",

  Payment = "Payment",

  AppStore = "AppStore",
}

export enum CardFieldNames {
  FullName = "FullName",

  BusinessName = "BusinessName",

  Headline = "Headline",

  CompanyName = "CompanyName",

  Department = "Department",

  Accreditation = "Accreditation",

  Email = "Email",

  ContactNumber = "ContactNumber",

  Website = "Website",

  JobTitle = "JobTitle",

  Logo = "Logo",

  DateStarted = "DateStarted",

  DateOfBirth = "DateOfBirth",

  ProfilePic = "ProfilePic",

  CoverPhoto = "CoverPhoto",

  Link = "Link",

  Address = "Address",

  Twitter = "Twitter",

  Instagram = "Instagram",

  LinkedIn = "LinkedIn",

  Facebook = "Facebook",

  YouTube = "YouTube",

  Snapchat = "Snapchat",

  Twitch = "Twitch",

  WhatsApp = "WhatsApp",

  Signal = "Signal",

  Discord = "Discord",

  Skype = "Skype",

  Telegram = "Telegram",

  GitHub = "GitHub",

  Calendly = "Calendly",

  PayPal = "PayPal",

  CashApp = "CashApp",

  AppleStore = "AppleStore",

  AppStore = "AppStore",

  MediaPlayer = "MediaPlayer",

  ImageSlider = "ImageSlider",

  GoogleReview = 'GoogleReview'
}
export interface UserContext {
  userId: number;
  clientAccountId: number;
  businessAccountIds: number[];
  employeeIds: number[];
  email: string;
  userName: string;
  isAdmin: boolean;
  userRoles: string[];
}

export interface User {
  id: number;
  employeeId: number;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  isAdmin: boolean;
  isActive: boolean;
  roles: { roleName: string }[];
}

export interface LogInResult {
  token: string;
}

export interface Team {
  id: number;
  name: string;
  description?: string;
  businessAccountId: number;
  imageUrl?: string;
  isActive: boolean;
  createdBy: number;
  createdOn: Date;
  updatedBy?: number;
  updatedOn?: Date;
}
export interface CodeMaster {
  id: number;
  codeType: string;
  code: string;
  value: string;
}

export interface NameItem {
  id: number;
  name: string;
}

export interface Employee {
  id: number;
  businessAccountId: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  fullName?: string;
  description?: string;
  descriptionRich?: string;
  pronouns?: string;
  imageUrl?: string;
  website?: string;
  teams: NameItem[];
  departments?: CodeItem[];
  jobTitle?: CodeItem;
  dateOfBirth?: Date;
  isActive: boolean;
  contactEmails?: ContactEmail[];
  contactNumbers?: ContactNumber[];
  socialMediaLinks?: SocialMediaLink[];
  addresses?: Address[];
}

export interface DigitalCardListItem {
  digitalCardId: number;
  label: string;
  description: string;
  cardType: CardTypes;
  parentId: number;
  parentName?: string;
  isOnline: boolean;
  isActive: boolean;
}
export interface RefData {
  jobTitles?: CodeMaster[];
  departments?: CodeMaster[];
  nameTitles?: CodeMaster[];
}

export interface CardApp {
  id: number;
  name: string;
  description: string;
  displayName: string;
  icon: string;
  url: string;
  qrCode: string;
  isActive: boolean;
  isExternal: boolean;
}

export interface CardAppItem {
  id: number;
  digitalCardId: number;
  cardAppId: number;
  appConfig: Dictionary<string>;
  qrConfig: QRConfig;

  app?: CardApp;
}

//app QRConfig
export interface QRConfig {
  shape: string;
  color: string;
  image: string;
  pattern: string;
}

export interface ContactUploadItem {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  description: string;
  pronouns: string;
  website: string;
  department: string;
  jobTitle: string;
  dateOfBirth: "2022-12-29T17:05:13.626Z";
  addressLine: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  facebook: string;
  linkedIn: string;
  tweeter: string;
  instagram: string;
  youTube: string;
  snapchat: string;
  whatsApp: string;
  skype: string;
  telegram: string;
}

export enum CardAppNames {
  BusinessApp = "BusinessCard",
  LeadApp = "LeadApp",
  ReviewApp = "ReviewApp",
}

export interface LeadRequest {
  cardId: number;
  contactEmail: string;
  contactMobile: string;
  contactName: string;
  remark: string;
}

export interface NfcCodeMap {
  nfcCode: string;
  cardId?: number;
  cardAppId?: number;
  isActive: string;
  remark?: string;
  groupName?: string;
  mapType?: string;
  mapTo?: string;
  cardApp?: CardApp;
}

export interface ConnectRequest {
  cardId: number;
  contactEmail: string;
  contactMobile: string;
  contactName: string;
  info?: string;
  meetingDate?: string;
  meetingLocation?: string;
  message?: string;
}

export interface ShareContactRequest {
  cardId: number;
  contactEmail: string;
  contactName?: string;
}

export interface SelfReview {
  cardId?: number;
  remark: string;
  contactName: string;
  contactEmail: string;
  contactMobile?: string;
}

export interface CardActionItem{
  id:number;
  clientAccountId :number;
  name:string;
  displayName:string;
  icon:string;
  tooltip?:string;
  order:number;
  config?:string;
  isActive:boolean;
}

export interface CardPathItem{
  id:number;
  clientAccountId :number;
  name:string;
}