import { Dictionary } from "lodash";
import { ReactElement } from "react";
import { CardFieldNames } from "../../../services/apiService/responseModels";
import AddressBlock from "../Address/block";
import AppleStoreBlock from "../AppleStore/block";
import AppStoreBlock from "../AppStore/block";
import CalendlyBlock from "../Calendly/block";
import CashAppBlock from "../CashApp/block";
import ContactNumberBlock from "../ContactNumber/block";
import DiscordBlock from "../Discord/block";
import EmailBlock from "../Email/block";
import GithubBlock from "../Github/block";
import ImageSliderBlock from "../ImageSlider/block";
import LinkBlock from "../Link/block";
import MediaPlayerBlock from "../MediaPlayer/block";
import PaypalBlock from "../Paypal/block";
import SignalBlock from "../Signal/block";
import SkypeBlock from "../Skype/block";
import TelegramBlock from "../Telegram/block";
import WebsiteBlock from "../Website/block";
import WhatsAppBlock from "../WhatsApp/block";
import FieldRenderBlockBaseProp from "./FieldRenderBlockBaseProp";
import GoogleReviewBlock from "../Google-review/block";

interface FieldRendererBase {
  renderBlock: (props: FieldRenderBlockBaseProp) => ReactElement;
}

const Build = (): Dictionary<FieldRendererBase> => {
  const registry: Dictionary<FieldRendererBase> = {};

  registry[CardFieldNames.ContactNumber] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <ContactNumberBlock {...props} />
    ),
  };

  registry[CardFieldNames.Email] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <EmailBlock {...props} />
    ),
  };

  registry[CardFieldNames.Website] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <WebsiteBlock {...props} />
    ),
  };
  registry[CardFieldNames.Link] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <LinkBlock {...props} />
    ),
  };

  registry[CardFieldNames.WhatsApp] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <WhatsAppBlock {...props} />
    ),
  };

  registry[CardFieldNames.Signal] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <SignalBlock {...props} />
    ),
  };

  registry[CardFieldNames.Discord] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <DiscordBlock {...props} />
    ),
  };

  registry[CardFieldNames.Skype] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <SkypeBlock {...props} />
    ),
  };

  registry[CardFieldNames.Telegram] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <TelegramBlock {...props} />
    ),
  };

  registry[CardFieldNames.GitHub] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <GithubBlock {...props} />
    ),
  };

  registry[CardFieldNames.Calendly] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <CalendlyBlock {...props} />
    ),
  };

  registry[CardFieldNames.PayPal] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <PaypalBlock {...props} />
    ),
  };

  registry[CardFieldNames.CashApp] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <CashAppBlock {...props} />
    ),
  };

  registry[CardFieldNames.AppStore] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <AppStoreBlock {...props} />
    ),
  };

  registry[CardFieldNames.AppleStore] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <AppleStoreBlock {...props} />
    ),
  };

  registry[CardFieldNames.MediaPlayer] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <MediaPlayerBlock {...props} />
    ),
  };

  registry[CardFieldNames.ImageSlider] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <ImageSliderBlock {...props} />
    ),
  };

  registry[CardFieldNames.Address] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <AddressBlock {...props} />
    ),
  };

  registry[CardFieldNames.GoogleReview] = {
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <GoogleReviewBlock {...props} />
    ),
  };

  return registry;
};

export default Build();
