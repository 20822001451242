import {
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import cdnService from "../../../services/cdnService";
import FieldRenderBlockBaseProp from "../_base/FieldRenderBlockBaseProp";
import {
  getPreFieldTitleDesc,
  getPreFieldValueDesc,
  getTypoStyle,
  ListItemWrapper,
} from "../_base/StyledComponent";

export default function ContactNumberBlock(props: FieldRenderBlockBaseProp) {
  const theme = useTheme();
  const title = getPreFieldTitleDesc(props.field);
    const value = getPreFieldValueDesc(props.field);

    const IsSmsNumber = () => {
        let flag = false;
        if (props.field.fieldValues) {
            if (props.field.fieldValues.find(o => o.name == 'IsSmsNumber')?.value == 'true')
                flag = true;
        }
        return flag;
    }

  return (
    <>
      <ListItemWrapper
              onClick={() => {
                  if (IsSmsNumber()) {
                      window.open(`sms:${value}`);
                  }
                  else {
                      window.open(`tel:${value}`);
                  }
        }}
        sx={{
          minWidth: 0,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        key={value}
      >
        <ListItemAvatar
          sx={{
            mr: 1,
            display: "flex",
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              background: `${theme.colors.alpha.white[10]}`,
              color: `${theme.colors.gradients.pink2}`,
              width: 64,
              height: 64,
            }}
          >
            <img src={cdnService.toCdnUrl("phone-number.png", "icons")} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{
            color: `${theme.colors.alpha.black[70]}`,
          }}
          primary={
            <Typography
              gutterBottom
              variant="h4"
              style={getTypoStyle(props.themeColor)}
            >
              {value}
            </Typography>
          }
          secondary={
            <Typography
              sx={{
                color: `${theme.colors.alpha.black[70]}`,
              }}
              variant="subtitle2"
              style={getTypoStyle(props.themeColor)}
            >
              {title}
            </Typography>
          }
        />
      </ListItemWrapper>
      <Divider />
    </>
  );
}
