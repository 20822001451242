import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  styled,
  Box,
  useTheme,
  alpha,
  CardMedia,
  CardActions,
  Link,
  Typography,
  Button,
  CardActionArea,
  CircularProgress,
  Paper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';

import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import cdnService from '../../../services/cdnService';
import { ListItemWrapper } from '../_base/StyledComponent';
import { getCardById } from '../../../services/apiService';
import { CardFieldNames } from '../../../services/apiService/responseModels';
import parseMyJson from '../../../utility/parseMyJson';

const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(5)};
        height: ${theme.spacing(5)};
        border-radius: 100px;
        transition: ${theme.transitions.create(['all'])};

        &:hover {
          color: ${theme.colors.alpha.black[100]};
          background: ${theme.colors.primary.lighter};
        }
`
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.info.main};
  color: ${theme.palette.info.contrastText};
  text-transform: uppercase;
  font-size: ${theme.typography.pxToRem(10)};
  font-weight: bold;
  line-height: 23px;
  height: 22px;
  padding: ${theme.spacing(0, 1.2)};
  border-radius: 50px;
`
);

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);

export interface ImageSliderType {
  title?: string;
  subTitle?: string;
  isPortrait?: boolean;
  imageList: ImageSliderItem[];
  profileRef?: ImageSliderProfileItem;
}

export interface ImageSliderItem {
  url: string;
  title?: string;
  subTitle?: string;
  targetUrl?: string;
}
export interface ImageSliderProfileItem {
  active: boolean;
  profileId?: number;
  sliderTitle?: string;
}

export default function ImageSliderBlock(props: ImageSliderType) {
  const theme = useTheme();
  const [fieldValue, setFieldValue] = useState<ImageSliderType>();
  useEffect(() => {
    (async () => {
      const defaultVal: ImageSliderType = {
        imageList: [],
      };
      const val: ImageSliderType = { ...props };
      // const val = readFieldValue(props.field, defaultVal);
      // console.log(val);
      if (props.profileRef?.active === true && props.profileRef?.profileId) {
        const cardRes = await getCardById(+props.profileRef.profileId);
        if (cardRes && cardRes.data) {
          const card = cardRes.data;
          const fields = card.fields.filter(
            (o) => o.fieldName == CardFieldNames.ImageSlider
          );

          let value: ImageSliderType = defaultVal;

          fields.forEach((field) => {
            const config = field.formFieldValues?.find(
              (o) => o.name == 'config'
            );
            const valRef: ImageSliderType = parseMyJson(config?.value || '');
            if (valRef && valRef.title == props.profileRef?.sliderTitle) {
              value = valRef;
            }
          });
          //overwrite
          val.imageList = value?.imageList || [];
        }
      }
      setFieldValue(val);
    })();
  }, [props.profileRef]);

  if (!fieldValue) {
    return (
      <Card
        sx={{
          overflow: 'hidden',
          textAlign: 'left',
          border: 'solid 1px #ccc',
          width: '100%',
          backgroundColor: 'inherit',
        }}
      >
        <Box
          sx={{
            height: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  return (
    <ListItemWrapper
      sx={{
        minWidth: 0,
      }}
    >
      <Card
        sx={{
          overflow: 'hidden',
          textAlign: 'left',
          border: 'solid 1px #ccc',
          backgroundColor: 'inherit',
        }}
      >
        {(fieldValue.title || fieldValue.subTitle) && (
          <CardHeader
            sx={{ pb: 0 }}
            title={fieldValue.title}
            subheader={fieldValue.subTitle}
          />
        )}
        <Box
          px={1}
          pb={2}
          sx={{
            '.swiper-pagination-bullets': {
              bottom: '0 !important',
            },
          }}
        >
          {!fieldValue.imageList.length ? (
            <Paper
              elevation={0}
              sx={{
                height: '100%',
                backgroundColor: '#f5dede',
                margin: 1,
                textAlign: 'center',
              }}
            >
              <ViewCarouselIcon
                fontSize="large"
                color="secondary"
                style={{ margin: '20%' }}
              />
            </Paper>
          ) : (
            <Swiper
              spaceBetween={12}
              slidesPerView={1}
              loop
              navigation={{}}
              modules={[Navigation, Pagination, Autoplay]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{ dynamicBullets: true, clickable: true }}
            >
              {fieldValue.imageList.map((o) => (
                <SwiperSlide key={o.url}>
                  <Card
                    sx={{
                      mt: 2,
                      textAlign: 'center',
                      transition: `${theme.transitions.create([
                        'box-shadow',
                        'transform',
                      ])}`,
                      transform: 'translateY(0px)',

                      '&:hover': {
                        transform: 'translateY(-10px)',
                        boxShadow: `0 2rem 8rem 0 ${alpha(
                          theme.colors.alpha.black[100],
                          0.1
                        )}, 
                                0 0.6rem 1.6rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.2
                                )}, 
                                0 0.2rem 0.2rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.15
                                )}`,
                      },
                    }}
                  >
                    <CardActionAreaWrapper>
                      <CardMedia
                        component="img"
                        height="400"
                        image={cdnService.toImageUrl(o.url)}
                        alt="..."
                        sx={{ objectFit: 'cover' }}
                        onClick={
                          o.targetUrl
                            ? () => window.open(o.targetUrl, '_blank')
                            : () => {}
                        }
                      />
                    </CardActionAreaWrapper>
                  </Card>
                  {(o.title || o.subTitle) && (
                    <Box
                      sx={{
                        px: { md: 2, lg: 1.5, xl: 3 },
                        pt: 2,
                        textAlign: 'center',
                      }}
                    >
                      {o.title && (
                        <Link
                          lineHeight={1.5}
                          target="_blank"
                          href={o.targetUrl}
                          sx={{
                            transition: `${theme.transitions.create([
                              'color',
                            ])}`,
                            color: `${theme.colors.alpha.black[100]}`,

                            '&:hover': {
                              color: `${theme.colors.primary.main}`,
                            },
                          }}
                          variant="h3"
                          underline="none"
                        >
                          {o.title}
                        </Link>
                      )}
                      {o.subTitle && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            pb: 2,
                          }}
                        >
                          {o.subTitle}
                        </Typography>
                      )}
                    </Box>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Box>
      </Card>
    </ListItemWrapper>
  );
}

ImageSliderBlock.defaultProps = {
  imageList: [],
};
