import { Dictionary } from 'lodash';
import { CardFieldNames, DigitalCardField } from '../services/apiService/responseModels';
import PreField from '../services/apiService/responseModels/PreField';

export const getFixedFields = (
  fieldHash: Dictionary<PreField>,
) => {
  const results: PreField[] = [];

  results.push(fieldHash[CardFieldNames.ProfilePic]);
  results.push(fieldHash[CardFieldNames.CoverPhoto]);
  results.push(fieldHash[CardFieldNames.FullName]);
  results.push(fieldHash[CardFieldNames.JobTitle]);
  results.push(fieldHash[CardFieldNames.Department]);
  results.push(fieldHash[CardFieldNames.CompanyName]);
  results.push(fieldHash[CardFieldNames.BusinessName]);
  results.push(fieldHash[CardFieldNames.Accreditation]);
  results.push(fieldHash[CardFieldNames.Headline]);

  //filter out null
  return results.filter((o) => o);
};

export const getFixedFields_Old = (
  fieldHash: Dictionary<DigitalCardField>,
) => {
  const results: DigitalCardField[] = [];

  results.push(fieldHash[CardFieldNames.ProfilePic]);
  results.push(fieldHash[CardFieldNames.CoverPhoto]);
  results.push(fieldHash[CardFieldNames.FullName]);
  results.push(fieldHash[CardFieldNames.JobTitle]);
  results.push(fieldHash[CardFieldNames.Department]);
  results.push(fieldHash[CardFieldNames.CompanyName]);
  results.push(fieldHash[CardFieldNames.BusinessName]);
  results.push(fieldHash[CardFieldNames.Accreditation]);
  results.push(fieldHash[CardFieldNames.Headline]);

  //filter out null
  return results.filter((o) => o);
};