import {
  Avatar,
  Box,
  Divider,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import cdnService from "../../../services/cdnService";
import { addHttpPrefixToUrl } from "../../../utils/addHttpPrefixToUrl";
import FieldRenderBlockBaseProp from "../_base/FieldRenderBlockBaseProp";
import { getTypoStyle, ListItemWrapper } from "../_base/StyledComponent";

export default function AddressBlock(props: FieldRenderBlockBaseProp) {
  const theme = useTheme();

  return (
    <>
      <ListItemWrapper
        onClick={() => {
          window.open(`https://maps.google.com/?q=${props.address}`, "_blank");
        }}
        sx={{
          minWidth: 0,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        key={props.address}
      >
        {/* <ListItemAvatar
          sx={{
            mr: 1,
            display: "flex",
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              background: `${theme.colors.alpha.white[10]}`,
              color: `${theme.colors.gradients.pink2}`,
              width: 64,
              height: 64,
            }}
          >
            <img src={cdnService.toCdnUrl("place-marker.png", "icons")} />
          </Avatar>
        </ListItemAvatar> */}
        <Box p={2} pb={0} sx={{ textAlign: "left" }}>
          <Typography
            variant="caption"
            fontWeight="bold"
            style={getTypoStyle(props.themeColor)}
          >
            {(props.isBusinessCard ? "Business " : "") + "Address"}
          </Typography>
          <Box p={2}>
            <Typography variant="h5" style={getTypoStyle(props.themeColor)}>
              {props.address}
            </Typography>
          </Box>
        </Box>
      </ListItemWrapper>
      <Divider />
    </>
  );
}
